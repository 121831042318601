import MicroModal from 'micromodal';

export default () => {
	/**
	 * Molal variables
	 */

	let modalWindow = $('.m-modal--team');
	let nextButton = $('.m-modal__next-btn');
	let prevButton = $('.m-modal__prev-btn');
	let closeButton = $('.m-modal__close');
	let speedAnimation = 100;

	/**
	 * Gets the value of the scroll bar
	 * */

	function getScrollBarWidth() {
		var inner = document.createElement('p');
		inner.style.width = '100%';
		inner.style.height = '200px';

		var outer = document.createElement('div');
		outer.style.position = 'absolute';
		outer.style.top = '0px';
		outer.style.left = '0px';
		outer.style.visibility = 'hidden';
		outer.style.width = '200px';
		outer.style.height = '150px';
		outer.style.overflow = 'hidden';
		outer.appendChild(inner);

		document.body.appendChild(outer);
		var w1 = inner.offsetWidth;
		outer.style.overflow = 'scroll';
		var w2 = inner.offsetWidth;
		if (w1 == w2) w2 = outer.clientWidth;

		document.body.removeChild(outer);

		return w1 - w2;
	}

	/**
	 * Setting the offset from the modal window to the width of the scrollbar
	 */

	function setPaddindForModal() {
		let allModal = $('.m-modal__outer-wrapper');
		let widthScrollBar = getScrollBarWidth();
		let windowHeight = $(window).height();

		$(allModal).each((i, el) => {
			let paddingTopOverlay = $(el)
				.find('.m-modal__overlay')
				.css('padding-top');
			let paddingBottomOverlay = $(el)
				.find('.m-modal__overlay')
				.css('padding-bottom');

			let isOpen = false;
			if ($(el).css('display') == 'block') {
				isOpen = true;
			}

			$(el).css('display', 'block');
			let modalHeight = $(el)
				.find('.m-modal__dialog')
				.outerHeight();

			!isOpen ? $(el).css('display', 'none') : '';

			if (
				windowHeight <
				modalHeight +
					parseInt(paddingBottomOverlay) +
					parseInt(paddingTopOverlay)
			) {
				$(el)
					.find(`.m-modal__overlay`)
					.css('padding-right', '0');
			} else {
				$(el)
					.find(`.m-modal__overlay`)
					.css('padding-right', widthScrollBar + 'px');
			}
		});
	}

	function correctArrowPosition() {
		let widthScrollBar = getScrollBarWidth();
		let nextButtonPosition = $(nextButton).css('right');
		$(nextButton).css(
			'right',
			parseInt(nextButtonPosition) + widthScrollBar + 'px',
		);
	}

	setPaddindForModal();
	correctArrowPosition();

	// Recalculation of indent values when resizing
	$(window).on('resize', setPaddindForModal);

	/**
	 * Function to close a modal window
	 *
	 * @param {*} id // Id modal
	 * @param {*} overlay // Whether to show overlay and pagination buttons
	 *                       when closing a modal
	 * @param {*} callback // Callback after animation
	 */

	function closeModal(id, overlay = true, callback) {
		MicroModal.close(id);
		$(`#${id}`).fadeOut(speedAnimation, callback);
		if (overlay) {
			visibleModalElements(false);
			$('body').css('overflow', 'visible');
		}
	}

	/**
	 * Function to show a modal window
	 *
	 * @param {*} id // Id modal
	 * @param {*} overlay  // Whether to show overlay and pagination buttons
	 *                        when closing a modal
	 * @param {*} callback // Callback after animation
	 */

	function showModal(id, overlay = true, callback) {
		MicroModal.show(id);
		$(`#${id}`).fadeIn(speedAnimation, callback);
		$('.m-modal__overlay').animate({ scrollTop: 0 }, 0);
		if (overlay) {
			visibleModalElements(true);
			$('body').css('overflow', 'hidden');
		}
	}

	/**
	 * Micromodal initialization.
	 */

	MicroModal.init({
		onShow: modal => {
			if ($(modal).parents('.m-modal').length) {
				showModal(modal.id);
			}
		},
	});

	/**
	 * Closes all open modals.
	 */

	closeButton.on('click', function() {
		closeModal(
			$(this)
				.parents('.m-modal__outer-wrapper')
				.attr('id'),
		);
	});

	/**
	 * Close the modal when clicking outside the overlay
	 */

	$('.m-modal__overlay').on('click', function(e) {
		if ($(this).get(0) == e.target) {
			closeModal(
				$(this)
					.parent()
					.attr('id'),
			);
		}
	});

	/**
	 * ESC close window
	 */

	$(document).on('keydown', function(e) {
		if (e.key == 'Escape') {
			visibleModalElements(false);
			$('.m-modal__outer-wrapper').fadeOut(300);
		}
	});

	/**
	 * Event handling when clicking on the "Next" and "Prev" buttons
	 * (version with buttons outside the modal window)
	 */

	nextButton.on('click', () => shangeSlide('next'));
	prevButton.on('click', () => shangeSlide('prev'));

	function shangeSlide(to) {
		let idCurrentOpenModal = $('.m-modal__outer-wrapper.is-open').attr(
			'id',
		);
		let parentCurrentModal = $(`#${idCurrentOpenModal}`).parent();
		let numCurrentModal;

		modalWindow.each(index => {
			if (modalWindow.eq(index).get(0) == parentCurrentModal.get(0)) {
				numCurrentModal = index;
			}
		});

		let nextModalNumber = numCurrentModal + 1;
		let prevModalNumber = numCurrentModal - 1;

		/**
		 * Clarifications for extreme indexes.
		 */

		if (numCurrentModal <= 0) {
			prevModalNumber = modalWindow.length - 1;
		} else if (numCurrentModal >= modalWindow.length - 1) {
			nextModalNumber = 0;
		}

		let nextModalId = modalWindow
			.eq(nextModalNumber)
			.find('.m-modal__outer-wrapper')
			.attr('id');
		let prevModalId = modalWindow
			.eq(prevModalNumber)
			.find('.m-modal__outer-wrapper')
			.attr('id');

		if (to == 'next') {
			showModal(nextModalId, true, () =>
				closeModal(idCurrentOpenModal, false),
			);
		}

		if (to == 'prev') {
			showModal(prevModalId, true, () =>
				closeModal(idCurrentOpenModal, false),
			);
		}
	}

	/**
	 * Show "bg-overlay" and "pagination" when a module window is open
	 */

	function visibleModalElements(bool) {
		let overlay = $('.m-modal__bg-overlay');
		let pagination = $('.m-modal__pagination');

		if (bool) {
			overlay.fadeIn(300);
			pagination.fadeIn(300);
		} else {
			overlay.fadeOut(300);
			pagination.fadeOut(300);
		}
	}
};
