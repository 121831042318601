function horizontalMenu() {
	// // Show or hide the list by clicking on the button.
	//
	// $('.o-i360-horizontal-menu__btn-menu').on('click', () => {
	// 	$('.o-i360-horizontal-menu__link-wrapper').toggleClass('is-close');
	// });
	//
	// // When resizing a window, we take into account the display. If md then hide the menu, if above then show
	//
	// $(window).on('resize', () => {
	// 	if ($(window).width() > 992) {
	// 		$('.o-i360-horizontal-menu__link-wrapper').removeClass('is-close');
	// 	} else {
	// 		$('.o-i360-horizontal-menu__link-wrapper').addClass('is-close');
	// 	}
	// });
}

export default horizontalMenu;
